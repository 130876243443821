import { Box, HStack, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { updateDrawer } from "../../store/actions/miscAction";
import { updateLocalSubscription } from "../../store/actions/subscriptionsActions";
const Actions = ({ subscription }) => {
  const { id } = subscription;
  const dispatch = useDispatch();
  const openDeleteSubscription = () => {
    dispatch(updateLocalSubscription(subscription));
    const drawer = {
      title: "Delete Subscription",
      isOpen: 1,
      content: { id, component: "DeleteSubscriptionForm" },
    };
    dispatch(updateDrawer(drawer));
  };

  return (
    <Box>
      <HStack>
        <Text
          mr={2}
          colorScheme="blue"
          color="var(--black)"
          cursor="pointer"
          aria-label="Edit"
          icon={<EditIcon />}
          onClick={() => {
            dispatch(updateLocalSubscription(subscription));
            const drawer = {
              title: "Update Module Subscription",
              isOpen: 1,
              content: { id, component: "SubscriptionForm" },
            };
            dispatch(updateDrawer(drawer));
          }}
        >
          Edit Subscription
        </Text>
        <Text
          color="var(--black)"
          cursor="pointer"
          onClick={() => {
            openDeleteSubscription();
          }}
          aria-label="Delete"
          icon={<DeleteIcon />}
          colorScheme="red"
        >
          Delete
        </Text>
      </HStack>
    </Box>
  );
};

export default Actions;
