import dayjs from "dayjs";
import { toast } from "react-toastify";
// loop through services and add a property to each service object that indicates if the service has children or not
export const checkServiceHasChildren = (services) => {
  return services.map((a) => {
    a.hasChildren = services.filter((b) => b.parent === a.id).length ? 1 : 0;
    return a;
  });
};

// loop through ticket array and add a property to each ticket object that indicates if the service exists or not
export const checkServiceExistsForTickets = (tickets, services) => {
  return tickets.map((a) => {
    a.serviceExistStatus = services.find((b) => b.id === a.serviceId) ? 1 : 0;
    return a;
  });
};

// find all services that have been delete but were present in the tickets array
export const findDeletedServices = (ticketsUpdated) => {
  return ticketsUpdated
    .map((a) => {
      return !a.serviceExistStatus ? a : null;
    })
    .filter(Boolean);
};

export const getReadableKeyFromObject = (obj, value) => {
  let key = Object.keys(obj).find((key) => obj[key] === value);
  return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
};

export const getNonWorkDays = (workWeek) => {
  return [0, 1, 2, 3, 4, 5, 6].filter((a) => !workWeek.includes(a));
};

export const getNonWorkDates = (date, nonWorkDays, daysInMonth) => {
  const nonWorkDates = [];
  for (let dayIndex = 1; dayIndex <= daysInMonth; dayIndex++) {
    const currentDay = date.date(dayIndex);
    if (nonWorkDays.includes(currentDay.day())) {
      nonWorkDates.push(currentDay.format("D"));
    }
  }
  return nonWorkDates;
};

//
export const mapHasData = (map) => {
  const maxLength = Math.max(...Object.values(map).map((arr) => arr.length));

  return maxLength > 0;
};

export const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600).toFixed(0);
  const minutes = Math.floor((seconds % 3600) / 60).toFixed(0);
  const remainingSeconds = (seconds % 60).toFixed(0);
  const formattedTime = `${padZero(hours)}:${padZero(minutes)}:${padZero(
    remainingSeconds
  )}`;
  return formattedTime;
};
function padZero(num) {
  return num < 10 ? `0${num}` : num;
}

export const formatNumber = (number) => {
  return number.toLocaleString();
};

//generate dates array between provided timestamps
export const generateDateArray = (startTimestamp, endTimestamp) => {
  const startDate = dayjs.unix(startTimestamp);
  const endDate = dayjs.unix(endTimestamp);

  const datesArray = [];
  let currentDate = startDate;

  while (currentDate.isBefore(endDate)) {
    datesArray.push(currentDate.format("MM-DD-YYYY"));
    currentDate = currentDate.add(1, "day");
  }

  return datesArray;
};



export const formatTicketNumber = (text) => {
  text = text.toUpperCase();
  //check if char at 0 is letter
  if (text.length > 0 && !Number.isNaN(Number(text.charAt(0)))) {
    return "";
  }

  //max 2 letters
  if (text.replace(/[^a-zA-Z]/g, "").length > 2) {
    console.log(text.replace(/[^a-zA-Z]/g, "").length, "letters");
    return null;
  }
  //max 4 numbers
  if (text.replace(/\D/g, "").length > 4) {
    return null;
  }
  const hasNumber = /[1-9]/.test(text);
  if (hasNumber && !text.includes("-")) {
    //insert - before the number
    let index = text.search(/\d/);
    text = text.substring(0, index) + "-" + text.substring(index);

    return text;
  }

  return text;
};

//generate hours array between provided timestamps
export const generateHoursArray = (startTimestamp, endTimestamp) => {
  const startDate = dayjs.unix(startTimestamp);
  const endTime = dayjs.unix(endTimestamp);

  const hoursArray = [];
  let currentTime = startDate;

  while (currentTime.isBefore(endTime)) {
    hoursArray.push(currentTime.format("h A"));
    currentTime = currentTime.add(1, "hour");
  }

  return hoursArray;
};

export const capitalizeName = (name) => {
  return name
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const convertTo24HourHour = (time12h) => {
  const [time, modifier] = time12h.split(" ");
  let hours = parseInt(time);

  if (modifier === "AM" && hours === 12) {
    hours = 0;
  } else if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }

  return hours;
};

export const redirectToAuth = (err, customErrorMsg) => {
  if (err.response && err.response.status === 401) {
    toast.error("Session expired. Please login again");
    window.location.replace(
      `https://auth.averly.com.na?origin=${window.location.href}`
    );
  }
  else
    toast.error(customErrorMsg || "An error occurred. Please try again later");
};

