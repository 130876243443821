import { Box, Text, Input } from "@chakra-ui/react";

const InputComponent = ({ label, value, onChange, placeholder, disabled=false, type="text" }) => {

    return (
        <Box
            w="100%"
            h="56px"
            borderRadius="8px"
            border="1px solid #b0b0b0"
            p="12px 5px 9px 12px"
        >
            <Text
                h="15px"
                lineHeight="15px"
                fontSize="12px"
                color="#6a6a6a"
            >{label}</Text>
            <Input
                placeholder={placeholder}
                value={value}
                type={type}
                borderRadius={0}
                borderColor="var(--black)"
                h="20px"
                w="100%"
                fontSize="16px"
                color="#222"
                p="0"
                m="0"
                outline="none"
                boxShadow="none"
                border="none"
                _focus={{
                    border: "none",
                    boxShadow: "none",
                    outline: "none"
                }}
                
                disabled={disabled}
                onChange={(e) => onChange(e.target.value)}
            />
        </Box>  
    );
}

export default InputComponent;