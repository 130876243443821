// Auth
export const FETCH_CLOUD_ORGANIZATIONS = "FETCH_CLOUD_ORGANIZATIONS";

//Add all action types here
export const ACTION_TYPES = Object.freeze({
  //Default reducer
  UPDATE_REPORT_DURATION: "UPDATE_REPORT_DURATION",
  UPDATE_APP_STATUS: "UPDATE_APP_STATUS",
  SET_AUTH_LOADING: "SET_AUTH_LOADING",
  GET_REPORTS_DATA: "GET_REPORTS_DATA",
  GET_REPORT_TYPES: "GET_REPORT_TYPES",
  UPDATE_TIME_NOW: "UPDATE_TIME_NOW",
  UPDATE_MODULE_ID: "UPDATE_MODULE_ID",
  GET_AUDIENCES: "GET_AUDIENCES",

  //Auth Reducer
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_ID: "SET_USER_ID",
  UPDATE_VERIFY_STATUS: "UPDATE_VERIFY_STATUS",
  GET_USER_PERMISSIONS: "GET_USER_PERMISSIONS",

  //permissions reducer
  GET_IAM_ROLES: "GET_IAM_ROLES",
  GET_IAM_PERMISSIONS: "GET_IAM_PERMISSIONS",
  SET_PERMISSIONS_LOADING: "SET_PERMISSIONS_LOADING",

  // modules reducer
  FETCH_MODULES: "FETCH_MODULES",
  SET_MODULES_LOADING: "SET_MODULES_LOADING",
  ADD_MODULE: "ADD_MODULE",
  UPDATE_LOCAL_MODULE: "UPDATE_LOCAL_MODULE",
  UPDATE_CREATING_MODULE: "UPDATE_CREATING_MODULE",

  LISTEN_MODULES_START: "LISTEN_MODULES_START",
  LISTEN_MODULES_CREATE: "LISTEN_MODULES_CREATE",
  LISTEN_MODULES_UPDATE: "LISTEN_MODULES_UPDATE",
  LISTEN_MODULES_DELETE: "LISTEN_MODULES_DELETE",


  //endpoints reducer
  FETCH_ENDPOINTS: "FETCH_ENDPOINTS",
  LISTEN_ENDPOINTS_START: "LISTEN_ENDPOINTS_START",
  LISTEN_ENDPOINTS_CREATE: "LISTEN_ENDPOINTS_CREATE",
  LISTEN_ENDPOINTS_UPDATE: "LISTEN_ENDPOINTS_UPDATE",
  LISTEN_ENDPOINTS_DELETE: "LISTEN_ENDPOINTS_DELETE",
  SET_ENDPOINTS_LOADING: "SET_ENDPOINTS_LOADING",
  UPDATE_LOCAL_ENDPOINT: "UPDATE_LOCAL_ENDPOINT",
  UPDATE_CREATING_ENDPOINT: "UPDATE_CREATING_ENDPOINT",
  ADD_ENDPOINT: "ADD_ENDPOINT",

  // subscriptions reducer
  FETCH_SUBSCRIPTIONS: "FETCH_SUBSCRIPTIONS",
  LISTEN_SUBSCRIPTIONS_START: "LISTEN_SUBSCRIPTIONS_START",
  LISTEN_SUBSCRIPTIONS_CREATE: "LISTEN_SUBSCRIPTIONS_CREATE",
  LISTEN_SUBSCRIPTIONS_UPDATE: "LISTEN_SUBSCRIPTIONS_UPDATE",
  LISTEN_SUBSCRIPTIONS_DELETE: "LISTEN_SUBSCRIPTIONS_DELETE",
  SET_SUBSCRIPTIONS_LOADING: "SET_SUBSCRIPTIONS_LOADING",
  UPDATE_LOCAL_SUBSCRIPTION: "UPDATE_LOCAL_SUBSCRIPTION",
  UPDATE_CREATING_SUBSCRIPTION: "UPDATE_CREATING_SUBSCRIPTION",
  ADD_SUBSCRIPTION: "ADD_SUBSCRIPTION",

  //sections reducer
  FETCH_SECTIONS: "FETCH_SECTIONS",
  LISTEN_SECTIONS_START: "LISTEN_SECTIONS_START",
  LISTEN_SECTIONS_CREATE: "LISTEN_SECTIONS_CREATE",
  LISTEN_SECTIONS_UPDATE: "LISTEN_SECTIONS_UPDATE",
  LISTEN_SECTIONS_DELETE: "LISTEN_SECTIONS_DELETE",
  SET_SECTIONS_LOADING: "SET_SECTIONS_LOADING",
  UPDATE_LOCAL_SECTION: "UPDATE_LOCAL_SECTION",
  UPDATE_CREATING_SECTION: "UPDATE_CREATING_SECTION",
  ADD_SECTION: "ADD_SECTION",

  // authorization reducer
  FETCH_AUTHORIZATIONS: "FETCH_AUTHORIZATIONS",
  LISTEN_AUTHORIZATIONS_START: "LISTEN_AUTHORIZATIONS_START",
  LISTEN_AUTHORIZATIONS_CREATE: "LISTEN_AUTHORIZATIONS_CREATE",
  LISTEN_AUTHORIZATIONS_UPDATE: "LISTEN_AUTHORIZATIONS_UPDATE",
  LISTEN_AUTHORIZATIONS_DELETE: "LISTEN_AUTHORIZATIONS_DELETE",
  SET_AUTHORIZATIONS_LOADING: "SET_AUTHORIZATIONS_LOADING",
  UPDATE_LOCAL_AUTHORIZATION: "UPDATE_LOCAL_AUTHORIZATION",
  UPDATE_CREATING_AUTHORIZATION: "UPDATE_CREATING_AUTHORIZATION",
  ADD_AUTHORIZATION: "ADD_AUTHORIZATION",

  // admin endpoints reducer
  FETCH_ADMIN_ENDPOINTS: "FETCH_ADMIN_ENDPOINTS",
  LISTEN_ADMIN_ENDPOINTS_START: "LISTEN_ADMIN_ENDPOINTS_START",
  LISTEN_ADMIN_ENDPOINTS_CREATE: "LISTEN_ADMIN_ENDPOINTS_CREATE",
  LISTEN_ADMIN_ENDPOINTS_UPDATE: "LISTEN_ADMIN_ENDPOINTS_UPDATE",
  LISTEN_ADMIN_ENDPOINTS_DELETE: "LISTEN_ADMIN_ENDPOINTS_DELETE",
  SET_ADMIN_ENDPOINTS_LOADING: "SET_ADMIN_ENDPOINTS_LOADING",
  UPDATE_LOCAL_ADMIN_ENDPOINT: "UPDATE_LOCAL_ADMIN_ENDPOINT",
  UPDATE_CREATING_ADMIN_ENDPOINT: "UPDATE_CREATING_ADMIN_ENDPOINT",

  // styling reducer
  FETCH_STYLINGS: "FETCH_STYLINGS",
  LISTEN_STYLINGS_START: "LISTEN_STYLINGS_START",
  LISTEN_STYLINGS_CREATE: "LISTEN_STYLINGS_CREATE",
  LISTEN_STYLINGS_UPDATE: "LISTEN_STYLINGS_UPDATE",
  LISTEN_STYLINGS_DELETE: "LISTEN_STYLINGS_DELETE",
  SET_STYLINGS_LOADING: "SET_STYLINGS_LOADING",
  UPDATE_LOCAL_STYLING: "UPDATE_LOCAL_STYLING",
  UPDATE_CREATING_STYLING: "UPDATE_CREATING_STYLING"

});
