import { Box, HStack, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { updateDrawer } from "../../store/actions/miscAction";
import { updateLocalEndpoint } from "../../store/actions/endpointsActions";
const Actions = ({ endpoint }) => {
  const { id } = endpoint;
  const dispatch = useDispatch();
  const openDeleteEndpoint = () => {
    dispatch(updateLocalEndpoint(endpoint));
    const drawer = {
      title: "Delete Endpoint",
      isOpen: 1,
      content: { id, component: "DeleteEndpointForm" },
    };
    dispatch(updateDrawer(drawer));
  };

  return (
    <Box>
      <HStack>
        <Text
          mr={2}
          colorScheme="blue"
          color="var(--black)"
          cursor="pointer"
          aria-label="Edit"
          icon={<EditIcon />}
          onClick={() => {
            dispatch(updateLocalEndpoint(endpoint));
            const drawer = {
              title: "Update Endpoint",
              isOpen: 1,
              content: { id, component: "EndpointForm" },
            };
            dispatch(updateDrawer(drawer));
          }}
        >
          Edit Endpoint
        </Text>
        <Text
          color="var(--black)"
          cursor="pointer"
          onClick={() => {
            openDeleteEndpoint();
          }}
          aria-label="Delete"
          icon={<DeleteIcon />}
          colorScheme="red"
        >
          Delete
        </Text>
      </HStack>
    </Box>
  );
};

export default Actions;
