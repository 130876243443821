import { Box, Button } from "@chakra-ui/react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import OrganizationSelection from "../../filterForm/OrganizationSelection";
import InputComponent from "../../averly/Input";
import { resetDrawer, updateDrawer } from "../../../store/actions/miscAction";
import { updateLocalSubscription } from "../../../store/actions/subscriptionsActions";
import BranchSelection from "../../filterForm/BranchSelection";
import ModuleSelection from "../subscriptionForm/ModuleSelection";
import AppOnMountEndpointsSelection from "../subscriptionForm/AppOnMountEndpointsSelection";
import IconSetSelection from "../subscriptionForm/IconSetSelection";
import IconNameSelection from "../subscriptionForm/IconNameSelection";

const SubscriptionForm = () => {
  const dispatch = useDispatch();
  const { subscription, creatingSubscription } = useSelector(
    (store) => store.subscriptionsReducer
  );
  const subscriptionClone = _.cloneDeep(subscription);

  const formActions = {
    onOrganizationChange: (organizationId) => {
      dispatch(
        updateLocalSubscription({ ...subscriptionClone, organizationId })
      );
    },
    onSubscriptionNameChange: (name) => {
      dispatch(updateLocalSubscription({ ...subscriptionClone, name }));
    },

    onBranchChange: (branchId) => {
      dispatch(updateLocalSubscription({ ...subscriptionClone, branchId }));
    },

    onModuleChange: (moduleId) => {
      dispatch(
        updateLocalSubscription({
          ...subscriptionClone,
          moduleId,
          moduleEndpoints: [],
        })
      );
    },

    onSubscriptionLabelChange: (label) => {
      dispatch(updateLocalSubscription({ ...subscriptionClone, label }));
    },

    onListOrderChange: (listOrder) => {
      dispatch(updateLocalSubscription({ ...subscriptionClone, listOrder }));
    },

    onAppOnMountEndpointsChange: (appOnMountEndpoints) => {
      dispatch(
        updateLocalSubscription({ ...subscriptionClone, appOnMountEndpoints })
      );
    },

    onIconSetChange: (iconSet) => {
      dispatch(
        updateLocalSubscription({ ...subscriptionClone, iconSet, iconName: "" })
      );
    },

    onIconNameChange: (iconName) => {
      dispatch(updateLocalSubscription({ ...subscriptionClone, iconName }));
    },

    onEndpointCreated: () => {
      dispatch(updateLocalSubscription({}));
      dispatch(resetDrawer());
    },
  };

  const onNext = () => {
    const drawer = {
      title: subscriptionClone?.id
        ? "Add Module Endpoints"
        : "Select Module Endpoints",
      isOpen: 1,
      content: { component: "ModuleEndpointsForm", id: null },
    };
    dispatch(updateDrawer(drawer));
  };
  return (
    <Box>
      <Box mb={5}>
        <OrganizationSelection
          onChange={formActions.onOrganizationChange}
          value={subscriptionClone?.organizationId || ""}
        />
      </Box>
      <Box mb={5}>
        <BranchSelection
          selectedOrganizationId={subscriptionClone?.organizationId}
          onChange={formActions.onBranchChange}
          value={subscriptionClone?.branchId}
        />
      </Box>
      <Box mb={5}>
        <InputComponent
          label="Name"
          placeholder="Main Subscription"
          value={subscriptionClone?.name}
          onChange={formActions.onSubscriptionNameChange}
        />
      </Box>
      <Box mb={5}>
        <ModuleSelection
          organizationId={subscriptionClone?.organizationId}
          value={subscriptionClone?.moduleId}
          onChange={formActions.onModuleChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="Label"
          placeholder="Payments"
          value={subscriptionClone?.label}
          onChange={formActions.onSubscriptionLabelChange}
        />
      </Box>

      <Box mb={5}>
        <InputComponent
          label="List Order"
          type="number"
          placeholder="1"
          value={subscriptionClone?.listOrder}
          onChange={formActions.onListOrderChange}
        />
      </Box>

      <Box mb={5}>
        <AppOnMountEndpointsSelection
          organizationId={subscriptionClone?.organizationId}
          onChange={formActions.onAppOnMountEndpointsChange}
          appOnMountEndpoints={subscriptionClone?.appOnMountEndpoints}
        />
      </Box>

      <Box mb={5}>
        <IconSetSelection
          value={subscriptionClone?.iconSet}
          onChange={formActions.onIconSetChange}
        />
      </Box>
      <Box mb={5}>
        <IconNameSelection
          iconSet={subscriptionClone?.iconSet}
          value={subscriptionClone?.iconName}
          onChange={formActions.onIconNameChange}
        />
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          w="200px"
          h="56px"
          isLoading={creatingSubscription}
          loadingText="Creating Endpoint..."
          borderRadius="8px"
          background="#222"
          color="var(--white)"
          _hover={{ bg: "#000" }}
          onClick={onNext}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default SubscriptionForm;
